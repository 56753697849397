body{
  background-color: $wrapper-bg;
}
a{
  text-decoration: none;
}
sup{
  top:-0.3em;
}

b, strong {
    font-weight: 700;
}

.l-wrapper{
   @if $enable-fluid-layout {
    @extend .container-fluid;
    &:not(.l-wrapper--boxed){
			max-width: 1920px; // blocage de la taille pour les 2560px
			#index &,#product &{max-width:inherit;}
	  }
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }


}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  border-top: 1px solid $card-border-color;
  &:empty{
    display: none;
  }

}

.breadcrumb {
		.breadcrumb-item{
			font-weight:400;
			letter-spacing: 0 !important;
			&.active{font-weight:700;}
		}
		@include media-breakpoint-mobile{
			overflow:scroll ;
			margin-bottom: 0.5rem;
			.breadcrumb-item{
		    white-space: nowrap;
		    &+.breadcrumb-item{
		    	padding-left:0.3rem;
		    	&:before{
		    		float:inherit;
		    		padding-right:0.3rem;
		    	}
		  	}
			}
		}
		@include media-breakpoint-down(lg) {
			#product & {
				overflow:scroll ;
				margin-bottom: 0.5rem;
				.breadcrumb-item{
			    white-space: nowrap;
			    &+.breadcrumb-item{
			    	padding-left:0.3rem;
			    	&:before{
			    		float:inherit;
			    		padding-right:0.3rem;
			    	}
			  	}
				}
			}
		}
}


.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */




/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;
  font-weight:800;
  color:$primary;
	.modal-content &{
		@include font-size($h2-font-size);
	}
}
h2,.h2{
  font-weight:600;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

.btn-primary{
	@extend .btn-outline-primary;
	background-color:transparent;
	text-transform:uppercase;	
	font-weight:800;
}
.btn-default{
	@extend .btn-primary;	
}

.btn-bg-primary{
	@extend .btn-add-to-cart;	
}

.btn-secondary{
	border-color:transparent;
	border-radius:0px;
	background:transparent;
	color:$body-color;
	border-width: 2px;
  padding: 3px 0px;
  border-bottom-color: $gray-300;
	&:hover,&:focus{
		background:transparent;
		color:$body-color;
		border-color:transparent;
		border-bottom-color: $body-color;
		box-shadow:none;
	}
}

.input-group-btn{
	.btn{
		border-radius:0 0.5rem 0.5rem 0;	
	}	
}

.breadcrumb{
	padding-left:0;	
}

//touch spin

.bootstrap-touchspin
{
  border:$input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
 	background: rgba(0,0,0,0.05);
 	.images-container &{
 		background: transparent;	
 	}
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $primary;
    border-width: 2px;
  }
}


  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $light;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 1px solid $border-color;
  border-top:3px solid $body-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;//$body-color;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
	.page-home > *{
  	margin-bottom: $spacer-y/2;
	}
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content:not(.page-content--contact),
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.ui-tooltip.ui-widget-content{
		background:$white;
		box-shadow:none;
		border-radius:0px;
		border:1px solid $body-color;
		color:$body-color;
		font-size:0.75rem;
		padding:0.2rem 0.25rem;
	}
	
.img-responsive{
	@extend .img-fluid;	
}

.clear {
    clear: both;
}