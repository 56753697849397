/* PRODUCT MINIATURE */
$pm-bg-color: null;
$pm-border-width: null;
$pm-border-color: null;
/* product title */
$pm-name-color:                $body-color;
$pm-name-hover-color:          $primary;
$pm-name-font-size:            1.375rem;
$pm-name-font-weight:          500;
$pm-name-font-family: null;
$pm-name-hover-border-width: 1px;
$pm-name-hover-border-color: transparent;

$pm-highlight-bg-color: #fff;
$pm-highlight-translateY: 0;