.search-widget{
  /*max-width: 450px;**/
  margin: 0 auto;
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  padding-right: 50px ;
}
.search-widget__btn{
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-mobile{
  .search-widget{
    max-width: unset;
    .icon_svg:not(use) >svg{
			height:26px;
			width:26px;	
			fill:$primary;
		}
		input[type="text"]{
   		border-width:0 0 1px 0;	
   }
  }
}

.btn-expand-collapse{
	display:none;
	span,.material-icons{
		color:$white;
	}
	&:hover i,&:hover .material-icons{color:$primary;}
}

/***sur desktop***/
.blocksearch{	
	#search_widget{
	transition: all 0.6s ease-in-out;	
	position: absolute;
    top:76px;
	 @include media-breakpoint-desktop{
	 	.headroom--not-top &{ top: 58px;}
	 }
	 @include media-breakpoint-mobile{
	 	top: 46px;
	 }
    left: 0;
    z-index: 3;
    width: 100%;
    background:$light;
   /* border: 1px solid $primary;
    border-width: 4px 0px 0px;*/
    form{
    	padding-top:2.5rem;
    	padding-bottom:2.5rem;	
    	@include media-breakpoint-mobile{
    		padding-top:2rem;
    		padding-bottom:2rem;		
    	}
    }
    input[type="text"]{
	   	border-color:$body-color;
	   	background:transparent;
	   	border-width:0 0 1px;
	   	text-align:center;
	   	font-weight:700;
	   	@include font-size(1.5625rem);
	    &:focus{box-shadow:none;}
	    &::placeholder,
    	&::-webkit-input-placeholder{
    		color: $body-color;
    	}
    	&::-moz-placeholder{
    		color: $body-color;
    	}
    	&:-moz-placeholder{
    		color: $body-color;
    	}
    }
    .btn{
    	border-bottom:1px solid $body-color;
    	&:not(use)>svg{
    		fill: $body-color;
    	}
    }
	}
	.btn-expand-collapse{
		cursor:pointer;
		display:block;
		.btn-expand-close{
					display:none;
					font-size:1.75rem;
					color:$white;	
		}
		&[aria-expanded="true"]{
				.btn-expand-open{display:none;}
				.btn-expand-close{
					display:block;
				}
		}
	}
}

.ui-autocomplete.ui-widget-content{
	z-index:1045!important;	
	padding:1rem !important;
}
