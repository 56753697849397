/*colonne droite*/
#js-checkout-summary{
	&.card {
		background:$light;	
	}
	.card-header{
		@extend .h2;
		border-bottom-width:0px;
		text-align:center;
		text-transform:initial !important;
	}
	.card-footer{
		border:0;	
		padding-top: 0.3rem;
	}
	.separator{
     border-color: $border-color;
	}
	.cart-summary-line{
		.value {
    font-weight: 700;
		}
	}
}

/*colonne centrale*/
/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-unreachable:not(.-reachable) {
  opacity:0.5;		
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}
.checkout-process{
	.checkout-step{
		border-width:1px;
		border-color:$border-color;	
		&:not(:first-child).card{
			border-top:0;	
		}
		&.-current{
			.step-title{
			}
		}
	}
	.card-header{
		padding-top: 1rem;
    padding-bottom: 1rem;
	}
	.step-title{
	}
	.step-edit{
			margin-top: 0.6rem;
	}
	.nav-tabs{
		border-bottom:1px solid $primary ;	
	}
	.alert-info{
		color:$body-color;
    background-color:$gray-100;
    border-color: $gray-100;
	}
}

.payment-options{
	>div{
		margin-bottom:1rem;	
	}
	.payment-option label img{
		display:none;	
	}
}

#payment-confirmation{
	.btn-primary{
  background-color:$primary;
  color:$white;
  &:hover{
  	background-color:transparent;
  	color:$primary;	
	 }
	}	
}