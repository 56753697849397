.l-footer{
  
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
  .footer-container{
  	background-color: $footer-bg;	
  }
}

.l-footer {
  color: $body-color;
  .cms-page-link,
  .account-list a,
  .links a{
    color: $body-color;
    font-size:0.875rem;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: 0;
   padding-top:0;
}

.footer__title{
  color:$body-color;
  &:visited,&:hover{
    color: $body-color;
  }
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
	.l-footer{
  margin-top:0;
  padding-top:0;
  }
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.block_newsletter{
	input{
		border-color:$dark;
	}
	.btn{
		border-radius:0;
	 }
	 .btn-primary{
	 	background:$dark;
	 	border-color:$dark;
	 	color:$white;
	 	&:hover{
	 		background:$white;
	 		color:$dark;	
	 	}	
	 }
	 a{
	 	font-size:0.75rem;
	 	font-style:italic;
	 	text-decoration:underline;
	 	text-underline-position:under;
	 }
}

.footer__copyright{
  text-align: center;
  font-size:0.875rem;
			padding-top:0rem;
			span{

				&:before{
					content: url(../img/icon_coeur.png);
    			padding-left: .1rem;
    			padding-right: 0.1rem;
				}	
				&:after{
					content: url(../img/icon_123.png);
    			padding-left: .1rem;	
				}	
			}
}	
/*.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}*/
