
.product-line-actions{
  display:flex;
  justify-content: space-between;
  align-items: center;
  .input-group.bootstrap-touchspin{
    max-width: 130px;
  }

}
.product-line-grid{
  .product-line__title{
    margin-bottom: $spacer*.75;
    display: inline-block;
    color: $body-color;
  }
  .product-price{
    display: flex;
    align-items: center;
    .discount{
      font-size: $font-size-sm;
      line-height: 1;
    }
  }
  .product-discount{
    margin-right: $spacer/2;
  }

}
.product-line__img{
  margin-right: $spacer;
}
@include media-breakpoint-mobile {
  .product-line__img{
    width: 100px;
    height: auto;
  }
  .product-line-actions {
  justify-content: flex-end;
    margin-top: $spacer;
    .product-price{
      margin:0 $spacer*2;
    }
  }
}
@include media-breakpoint-mobile() {
  .product-line__img{
    width: 50px;
  }
  .product-line-actions {
    .product-price{
      margin:0 $spacer;
    }
  }

}


.cart-grid-body{
	.card{
		border:0;	
	}	
	.card-block{
		padding:1rem;
	}
	.card-header{
		border-bottom-color:$body-color;	
		border-bottom-width:4px;
		margin-bottom:0;
		text-align:center;
		line-height:1;
	}
	.btn-outline-primary{
		font-size:0.9375rem;	
	}
}

.cart__card-body{
  position: relative;
   border: 1px solid $border-color;
  border-top-color:$body-color;	
 .cart__card-loader{
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   align-items: center;
   justify-content: center;
   display: none;
   background-color: rgba($white, .85);
   opacity: 0;
   transition: $transition-fade;
   z-index: 3;
 }
  &.is--loading{
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }

  }
}
.cart-grid-right{
	.card{
	}
	.card-header{
		border-bottom-width:0px;
		text-align:center;
		padding-top:20px;
	}
	.cart-summary{
		background:$light;
	}
	.card-footer{
		border:0;	
		padding-top: 0.3rem;
	}
	.btn-primary{
  background-color:$primary;
  color:$white;
  &:hover{
  	background-color:transparent;
  	color:$primary;	
	 }
	}
}
.nav-tabs--center{
  justify-content: center;
}
@include media-breakpoint-mobile{
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    background-color: rgba(0,0,0,.4);
    a{
      width: 100%;
    }
  }
}
.product-line__body{
  padding-right: $spacer/4;
}

//order confirmation table
.total-value{
 font-weight: 700;
}
.table__title-head{
  font-size: $font-size-base;
   font-weight: 700;

}
/* CART */
.promo-code{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}
.promo-code-alert {
  display: none;
}
.cancel-promo{
  text-align: center;
}
.card-body .separator{
  border-color:$border-color;
}

.cart-summary-line{
  display: flex;
  justify-content: space-between;

}
.cart-total{
font-weight: bold;
}
.card-body--summary{
	.cart-summary-totals{
		padding: $spacer/4;
	}
	.cart-total{
	  @extend .total-value;
	  .label{
	    margin-bottom: 0;
	  }
	}
  &.card-body{
    padding-bottom: 0;
  }
}
.media-list__item:not(:last-child){
  margin-bottom: $spacer/2;

}

//cart summary
.cart-detailed-totals{
	.cart-summary-line{
		.value{
			font-weight:700;
			/*#abfd_checkout_subtotal_details{
				margin-bottom: 0.5rem;
				margin-top:1px;
			}	*/
		}	
	}
}
.cart-detailed-actions{
	.btn-checkout{
		@include media-breakpoint-desktop {
			max-width: 70%;
    	margin: 0 auto;
		}
	}
}
.link__showsummary{
  .material-icons{
    @extend .icon-collapse;
  }
  &[aria-expanded=true] .material-icons{
    transform: rotateZ(180deg);

  }
}
.cart-item:not(:last-child){
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
.product-line-info{
  margin-bottom: $spacer/2;
   &.product-line-info-secondary{
  	margin-bottom:0;
  	.label{margin-bottom:0;}
  }
}
.promo-code-button{
  text-align: center;
  .collapse-button[aria-expanded=true] {
    display: none;
  }
}
.with-discounts{


}
.promo-highlighted{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts{
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-700;
  .code{
    color: $tertiary;
  }
}
.form__add-voucher{
  margin-bottom: $spacer;
}
.promo-code{
  &__content{
		padding: $card-spacer-x/2  $card-spacer-x;
		.form-control{
			background-color:$white;	
    	border-color:$white;	
    	color:$body-color;
    }
    .btn{
			border-radius:0;
	 	}	
	 	.btn-primary{
		 	background:$dark;
		 	border-color:$dark;
		 	color:$white;
		 	font-size:$small-font-size !important;
		 	&:hover{
		 		background:$white;
		 		color:$dark;	
		 	}	
  	}
  }
 .collapse-button{
   text-align: center;
   display: block;
   color: $primary;
 }
}
