/* carousel navigation styles */
.sw-nav, .n-hover:hover .sw-nav {
	display: block;
	position: absolute;
	left: 20px;
	top: 50%;
	width: 50px;
	height: 50px;
	/*background: rgba(0,0,0,0.5);
	box-shadow: 0px 0px 15px rgba(255,255,255, 0.3);*/
	border-radius: 50%;
	margin-top: -25px;
	z-index: 10;
	cursor: pointer;
	text-align: center;
	opacity: 1;
	outline: none;
	transition: all 0.1s ease-in-out;
}
.sw-nav.next, .n-hover:hover .sw-nav.next {
	transform: rotate(180deg);
	right: 20px;
	left: auto;
	opacity: 1;
}
.sw-nav:before {
	content: '';
	display: inline-block;
	width: 10px;
	height: 10px;
	border: 2px solid #FFF;
	transform: rotate(45deg);
	border-width: 0 0 2px 2px;
	margin-top: 20px;
	margin-left: 2px;
}
.sw-nav:hover, .n-hover:hover .sw-nav:hover {
	background: rgba(0,0,0,0.7);
}
.n-hover .sw-nav {
	left: 0;
	right: auto;
	opacity: 0;
}
.n-hover .sw-nav.next {
	right: 0;
	left: auto;
	opacity: 0;
}
.sw-pgn, .p-hover:hover .sw-pgn {
	margin-top: 15px;
	display: block;
	text-align: center;
	z-index: 10;
	transition: all 0.1s ease-in-out;
	opacity: 1;
}
.sw-pgn-bullet {
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #333;
	border: 2px solid #FFF;
	border-radius: 100%;
	margin: 0 3px;
	opacity: 0.2;
	outline: none;
	cursor: pointer;
}
.sw-pgn-bullet.active {
	opacity: 1;
}
.p-hover .sw-pgn {
	bottom: 0;
	opacity: 0;
}
.cb .cb-wrapper .hidden {
	display: none;
}



.cb-item {
	text-align: center;
	position: relative;
}
.cb-item-content{
	position: relative;
}
.cb-item-content .cb-img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.cb-item-content .cb-img.secondary-image,
.cb-item-content:hover .cb-img.primary-image {
	display: none;
}
.cb-item-content:hover .cb-img.secondary-image {
	display: inline-block;
}
.html-over .custom-html {
	display: table;
	margin: auto;
	position: absolute;
	width: 100%;
	top: 0; left: 0; bottom: 0; right: 0;
}
.img-rb .cb-item-content img {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
/* carousels */
.cb-wrapper .cb-carousel {
	visibility: hidden;
	max-height: 50px;
}
.cb-wrapper .cb-carousel.swiper-container-horizontal {
	visibility: visible;
	max-height: none;
}
/* accordion */
.cb-wrapper.type-4 {
	margin-top: 1px;
}
.cb-wrapper.type-4 .cb-item {
	width: 100%;
}
.cb-wrapper.type-4 .cb-item-title {
	text-align: left;
	padding: 10px;
	border: 1px solid #D0D5DB;
	margin-top: -1px;
	cursor: pointer;
}
.cb-wrapper.type-4 .cb-item-title:before {
	content: '+';
	font: bold 1.2em/1 Arial;
	color: #AAB2BD;
	margin-right: 5px;
}
.cb-wrapper.type-4 .cb-item.active .cb-item-title:before {
	content: '−';
}
/* grid values for 480 px. They are not present in BS 3 */
@media (max-width: 479px) {
	[class*=".col-xxs-"]{ float: left; }
	.col-xxs-1  { width: 8.3%; }
	.col-xxs-2  { width: 16.6%; }
	.col-xxs-3  { width: 25%; }
	.col-xxs-4  { width: 33.3%; }
	.col-xxs-5  { width: 41.8%; }
	.col-xxs-6  { width: 50%; }
	.col-xxs-7  { width: 58.3%; }
	.col-xxs-8  { width: 66.6%; }
	.col-xxs-9  { width: 75%; }
	.col-xxs-10 { width: 83.3%; }
	.col-xxs-11 { width: 91.6%; }
	.col-xxs-12 { width: 100%; }
}

/* special classes used in layout */
.cb .compact-gutters-container {
	margin: 0 -5px;
}
.cb .compact-gutters {
	padding: 0 5px 9px 5px;
}
.box-shadow, .img-box-shadow img {
	box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
}
.clear-both {
	clear: both;
}
/* since 2.9.9 */


.cb.displayFooter{
	@extend .col-xl-9;
	@extend .col-12; 
	@extend .order-3;
	@extend .order-lg-2;
	.cb-item{
		text-align:left;
	}
}


.page-home{	
	div[class*="box-"]{
	/*	@extend .click-all;*/
		h1{
			strong{
				font-weight:300;
			}		
		}
		h2,.h2{
			@extend h1;
		}
		.cb-item{
			text-align:left;
			.cb-img{
				width: 100%;	
			}
			&:not(.h2):not(.html-over) .custom-html{
				@include font-size(1.125rem);
				font-weight:300;	
				p{
					margin-bottom:1.5rem;	
				}
			}
		}
	}
}

.home-slider{
	.cb-item{
			text-align:center !important;
	}	
	.cb-item-content {
		background:$black;
		img.cb-img {
			opacity:0.6;
		}
		.cb-img{
				width: 100%;	
				height:100vh;
				object-fit:cover;
		}
	}
	
	.html-over .custom-html {
		top: 50%;
		bottom: auto;
  	transform: translateY(-42%);
		padding:1rem;
		color:$white;
		@include font-size(2.1875rem);
		font-weight:300;
		img{
			max-width: 100%;
			height: auto;
		}
		strong{
			@include font-size(7.58975rem);
			font-weight:800;
			line-height:1;
		}
		.btn-default{
			color: $white;	
    	border-color: $white;	
    	font-weight: 500;
    	@include font-size(1.125rem);
    	@include hover-focus() {
    		color: $primary;	
    		background-color: $white;		
    	}
		}
	}
	.text-primary.html-over .custom-html {
			color:$primary;	
			.btn-default{
				color: $primary;	
	    	border-color: $primary;	
	    	@include hover-focus() {
	    		color: $white;	
	    		background-color: $primary;		
	    	}
		}
	}
}

.displayHome{
	display:flex;
	flex-direction:column;	
}

.box-features-title{
	.cb-item{
			text-align:left;
		}
	.cb-item-content{
		@include media-breakpoint-down(xl) {
			margin-left:15px;
		}
	}

}
.box-features{
	@extend .d-flex;
	.cb-item-content img{
		background:#f6f6f6;	
	}
	.custom-html {
		padding-top:0.8rem;
		@include font-size(1.5rem);
		font-weight:500;
		p{margin-bottom:0.3rem !important;}
		strong{
			border:1px solid $body-color; 
			border-radius: 4px;
	    padding-left: 4px;
	    padding-right: 4px;
			cursor:pointer;
		}
		em{
			font-style:normal;
			font-weight:800;	
		}
	}
}

.box-three_bloc,
div[class*="box-two_blocs"],
.box-video{
	@extend .d-flex;
	/*@extend .flex-wrap;*/
	@extend .flex-column;
	@extend .flex-sm-row;
}

.box-three_bloc{

	position:relative;
	
	>.cb-item{
		transform:translateY(-25%);
		@include media-breakpoint-down(xl) {
				transform:translateY(-20%);
		}
	}
	.img_small{
		position: absolute;
    bottom:0;
    left: 50%;
    transform: translateX(-38%);
    z-index: 2;
    border: 10px solid $white;
	}
	.cb-item:nth-child(2){
		.cb-item-content{
			margin-left:5%;	
			margin-right:15px;	
			@include media-breakpoint-down(md) {
				margin-right:0%;
				margin-left:0%;	
			}
		}
	}
	.custom-html{
			margin-right:5%;	
			margin-left:5%;	
			@include media-breakpoint-down(md) {
				margin-left:0%;
				margin-right:0%;	
			}
			
	}
}

.box-two_blocs-left{

	>.cb-item{transform:translateY(-10%) }
	.cb-item.image{
		.cb-item-content{
			margin-left:15px;	
			margin-right:5%;	
			@include media-breakpoint-down(xl) {
				margin-right:15%;		
			}
			@include media-breakpoint-down(md) {
				margin-right:0%;
				margin-left:0%;	
			}
		}
	}	
	.cb-item.texte{
		.custom-html{
				margin-left:12%;	
				margin-right:5%;	
				@include media-breakpoint-down(xl) {
						margin-left:0%;	
						margin-right:10%;	
				}
				@include media-breakpoint-down(md) {
					margin-left:0%;
					margin-right:0%;	
				}		
		}
	}
}



.box-two_blocs-right{
	>.cb-item{	transform:translateY(0%);}

	.cb-item.image{
		transform:translateX(-20.2%);
		.cb-item-content{
			margin-left:5%;
			img{
				border:15px solid $white;
				transform:translateY(10%);
				@include media-breakpoint-down(xl) {
					transform:translateY(23%);	
				}
			}
		}
	}
	.cb-item.texte{
		transform:translateX(15.7%);
		.cb-item-content{
			padding : 3rem 28% 2rem 10%;
			background-color:$light;
	    @include media-breakpoint-down(xl) {
	    		padding :3rem 15% 1.5rem 8%;
	    }
		}
		@include media-breakpoint-down(xl) {
			transform:translateX(0%);	
			.cb-item-content{
			    margin-left: 15px;
			}
		}
	}
}


.box-video{
	.cb-item.video{
		.cb-item-content{
			z-index: 1;
		}
		.custom-html{
			p{
				margin-bottom:0 !important;
				line-height: 0;
			}
			video{
				width:100%;
				height:auto;
				border:15px solid $white;
			}
		}
	}
	&.box-video-right{
		@extend .flex-column-reverse;
		@extend .flex-sm-row;
		.cb-item.video{
			.cb-item-content{
				margin-left:9%;	
				@include media-breakpoint-down(xl) {
						margin-left:0%;	
						margin-right:9%;	
			 	}
			 	@include media-breakpoint-down(md) {
					margin-right:0%;
				}
			}
		}
		.cb-item.texte{
			.custom-html{
				margin-right:5%;	
				@include media-breakpoint-down(xl) {
					margin-left:15px;	
				}
				@include media-breakpoint-down(md) {
					margin-right:0%;
					margin-left:0px;	
				}
			}	
		}
			
	}
	&.box-video-left{
		.cb-item.video{
			.cb-item-content{
				transform:translateY(-10%);
			}
		}
		.cb-item.texte{
		transform:translateX(-13.8%);
		@include media-breakpoint-down(xl) {
			transform:translateX(-12.3%);	
		}
		.cb-item-content{
			padding : 3rem 15% 2rem 22%;
		}
		}
	}
	
	@include media-breakpoint-down(sm) {
			.cb-item.video{
				padding-left:0;
				padding-right:0;
				.cb-item-content{
						margin-left:0;	
						margin-right:0;	
				}
				.custom-html{
					video{
						border:0px solid $white;
					}
				}
			}
			.cb-item.texte{
				padding-top:1rem;
				.custom-html{
					margin-left:0;	
					margin-right:0;	
				}	
			}
			&.box-video-left{
				.cb-item.video{
					.cb-item-content{
						transform:translateY(0);
					}	
				}
				.cb-item.texte{
					transform:translateX(0);
					.cb-item-content{
						padding :0;
					}
				}
			}
	}	
}



/*.box-video1{
	@extend .flex-column-reverse;
	@extend .flex-sm-row;
	.cb-item.video{
		.cb-item-content{
			z-index: 1;
			margin-left:9%;	
			@include media-breakpoint-down(xl) {
					margin-left:0%;	
					margin-right:9%;	
		 }
			@include media-breakpoint-down(md) {
				margin-left:0%;
			}
		}
		.custom-html{
			p{
				margin-bottom:0 !important;
				line-height: 0;
			}
			video{
				width:100%;
				height:auto;
				border:15px solid $white;
			}
		}
	}
	.cb-item.texte{
			.custom-html{
				margin-right:5%;	
				@include media-breakpoint-down(xl) {
					margin-left:15px;	
				}
				@include media-breakpoint-down(md) {
					margin-right:0%;
				}
			}	
	}
	@include media-breakpoint-down(sm) {
			.cb-item.video{
				padding-left:0;
				padding-right:0;
				.cb-item-content{
						margin-left:0;	
						margin-right:0;	
				}
				.custom-html{
					video{
						border:0px solid $white;
					}
				}
			}
			.cb-item.texte{
				padding-top:1rem;
			.custom-html{
					margin-left:0;	
					margin-right:0;	
				}	
			}
	}	
}

.box-video-reverse1{
	&.box-video{
		@extend .flex-column;
		@extend .flex-sm-row;
	}
	.cb-item.video{
		.cb-item-content{
			transform:translateY(-10%);
			margin-left:0;
			@include media-breakpoint-down(md) {
				margin-right:0%;
				margin-left:0%;	
			}
		}	
	}
	.cb-item.texte{
		transform:translateX(-13.8%);
		@include media-breakpoint-down(xl) {
			transform:translateX(-12.3%);	
		}
		.cb-item-content{
			padding : 3rem 15% 2rem 22%;
			.custom-html{
				margin-right:0;
				@include media-breakpoint-down(md) {
					margin-right:0%;
					margin-left:0%;	
				}
			}	
		}
	}
	@include media-breakpoint-down(sm) {
		.cb-item.video{
			.cb-item-content{
				transform:translateY(0);
			}	
		}
		.cb-item.texte{
			transform:translateX(0);
			.cb-item-content{
				padding :0;
			}
		}
	}
}*/

.box-bg_light{
	position:relative;
	
	.bg_light{
		background-color:$light;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height:60%;
    z-index: -1;
	}
}

.box-bg_green{
	.cb-item.texte{
		.cb-item-content{
			background-color:$green;
			color:$white;
			h1{color:$white;}
				@include media-breakpoint-down(sm) {background-color:transparent;}
		}
		@include media-breakpoint-down(sm) {
			background-color:$green;
			.cb-item-content{
					background-color:inherit;
			}	
		}
	}
	.btn-default{
			color: $white;	
    	border-color: $white;	
    	@include hover-focus() {
    		color: $success;	
    		background-color: $white;		
    	}
	}

}



.box-mobile{
	margin-top:1.5rem;
	.cb-item.image{
		padding-left:0;
		padding-right:0;
	}
	.cb-item.texte{
		padding-top:1rem;
	}
}

.box-reassurance-home{
	@extend .d-flex;
	margin-bottom:5rem;	
	.cb-item{
			text-align:center !important;
			.cb-img{
				width: auto !important;	
			}
			.custom-html{
				margin-top: 0.5rem;
				p{
						margin-bottom:0rem !important;	
						&+p{font-size:1rem !important;}
				}
			}
	}
	@include media-breakpoint-mobile-portrait() {
		margin-bottom:0rem;	
	}
	
}

.cb.displayFooterBefore{width:100%;}


.header-banner{
	.top-banner{
		background:$secondary;
		color:$white;
		text-align:center;
		padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    overflow: scroll;
    @include media-breakpoint-desktop{
    	padding-top: 0.2rem;
    	padding-bottom: 0.2rem;
    	overflow: auto;
    }
		a{
			color:$white;
			span{white-space: nowrap;}
		}
		p{margin-bottom:0px;}
	}
}


.box-instagram{
	.cb-item{
		p{
			line-height:1;
			@extend h1;
			strong{
				font-weight:300;
			}	
			&:first-child{
				margin-bottom:0;
			}	
		}
		.btn-default{
				font-size: 1.125rem;
				text-transform:inherit;
				padding:1px 4px;
		}
	}
}