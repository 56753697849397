.products{
  @extend .row;
  align-items:stretch;
	@include  media-breakpoint-mobile-portrait {
		padding-right: $grid-gutter-width * .25;
    padding-left: $grid-gutter-width * .25;
	}
}
.product-miniature{
  @extend .col-6;  
  @extend .col-md-4;
  #product &{
  	@extend .col-12;
  	@extend .col-md-3;
  }
 	
 	
	@include  media-breakpoint-mobile-portrait {
		padding-right: $grid-gutter-width * .25;
    padding-left: $grid-gutter-width * .25;
	}
}
@include media-breakpoint-desktop {
  .product-miniature{
    @include make-col(4);
  }

  .layout-full-width .product-miniature{
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature{
    @include make-col(12);
  }
}

.slick-slide .product-miniature{
  max-width: 100%;
}



/* Product miniature*/
.card-product.card{
  height:100%;
  position: relative;
  background-color: $pm-bg-color;
  border-width: $pm-border-width;
  border-color: $pm-border-color;
  &:hover
  {
    .highlighted-informations{
      transform:translateY(0);
    }

  }
  .card-body{
  	padding-left:0;
  	padding-right:0;
  		
  }

  .discount{
    display:none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img{
    width: 100%;
  }
  .ar-ai-container .ar-ai-h-segment.active+img{
  	background:#f5f5f5;	
  }

}

.highlighted-informations{
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;
  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}
.quick-view{
  cursor:pointer;
}

.product-flags{
  position:absolute;
  top:0;
  margin-bottom: 0;
  z-index: 1;
  right:0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.product-flag{
  top:0;
  margin-bottom: $spacer/2;
  padding:5px 15px;
  color: $white;
  background: theme-color('primary');
  font-weight: 700;
  font-size:13px;

  &.discount-product{
    background: $tertiary;
  }
}
.page-content--product .product-flags{
  right:15px;
  .product-flag{
  	font-size:1rem;
  	margin-top: $spacer/2;
  	margin-bottom:0;
  }
}
.color{
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-title{
  color: $pm-name-color;
  @include font-size($pm-name-font-size);
  @include media-breakpoint-down(md) {
  	@include font-size(1rem);
  }
  text-align: left;
  text-decoration: none;
  font-weight: $pm-name-font-weight;
  & a {
    color: $pm-name-color;
    &:hover{
      color: $pm-name-hover-color;
      border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
    }

  }
  .card-product:hover & {
    color: $pm-name-hover-color;
    & a {
      color: $pm-name-hover-color;
    }
  }
}

/* PRODUCT PAGE */
#product{
	.breadcrumb .breadcrumb-item.active{
			display:none;
	}
/*	position:relative;*/
	#left-column{
		.page-content--product{
			background:$light;	
			@include media-breakpoint-mobile-portrait(){
				margin-left:-15px;
				margin-right:-15px;	
			}
			
		}
	}
	h1.title_header,.h1.title_header{
		margin-bottom:0.5rem;	
		margin-top:2rem;
	}
}
.images-container{
		padding: $grid-gutter-width * .5;
		@include media-breakpoint-mobile-portrait(){
					padding-bottom:$grid-gutter-width * .25;
			}
}
.products-imagescover{
  box-shadow:$card-box-shadow;;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc{
  display: block;
}

.product-thumbs{
  width:$product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin:0 auto;
  @include media-breakpoint-tablette {
  		width:$product-thumb-wrap-width-tablet;
  }
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

}
.product-thumb{
  margin: $product-thumb-margin;
  //padding:5px;
  //height:  auto;
  width:$product-thumb-width;
  height:$product-thumb-height;
  border:1px solid transparent;
  &.slick-current {
    border: 1px solid $gray-300;
  }
}
.btn-zoom{

  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  & .material-icons{
  font-size: 3*$font-size-base;
  }
}
.images-container:hover .btn-zoom{
  display: block;
}
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
  width:38px;
  height:38px;	
  border:2px solid transparent;
  border-radius:0.5rem;
  transition: .5s;
 &.label-color-large{
 		width:98px;	
 		height:50px
 	} 
 &.label-color-active{
  	width:36px;
  	height:36px;	
  	border: 2px solid $colorbox-border-color;
  	padding:4px;
  	span.color{
		  border-color: $colorbox-border-color;
		  width: 24px;
		  height: 24px;
		}
		&.label-color-large{
 			width:98px;	
 			height:50px;
 			padding:0;
 			border-color:transparent;
 			span.color{
			 width:98px;	
 			height:50px;
			}
 		}
  }
}
.product-variants-item{
	.label{
		font-weight:700;	
	}
	.color{
  	width:34px;
	  height:34px;
	  border: 1px solid rgba(0,0,0,.05);
	  border-radius:0.5rem;
	}
	.texture{
		background-repeat:no-repeat;	
		background-size: cover;
	}
	.label-color-large .color{
	  	width:98px;	
 			height:50px;	
	}
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price.has-discount{
  .current-price{
    display: inline-block;
  }
}
.price{
  color:$product-price-current-color;
   @include font-size($product-price-current-font-size);
  font-family: $product-price-current-font-family;
  &.current-price-discount{
    color:$product-price-discount-color;
     @include font-size($product-price-discount-font-size);
    font-weight:$product-price-discount-font-weight;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price{
  color:$product-price-regular-color;
   @include font-size($product-price-regular-font-size);
  font-weight:$product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount{
  text-transform: uppercase;
  color: #FFF;
  background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: .25*$spacer;
}

.product__product-price{
	.price{
  	@include font-size(1.625rem);
  	font-weight:$product-price-current-font-weight;
  	
	}
	.regular-price{
 	 @include font-size(1.625rem);
	}
	&.has-discount{
  .current-price{
    display: inline-block;
  }
 }
}

//product add to cart
.btn-add-to-cart{
  //@extend .d-flex;
 padding-left:6rem;
 padding-right:6rem;
 @include media-breakpoint-mobile-portrait(){
 	padding-left:5rem;
 	padding-right:5rem;
 	background-color:$primary;
 	color:$white;
 	&:hover{
 		background-color:$white;
 		color:$primary;	
 	}
 }
  white-space: nowrap;
}


.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
  .thumb-mask{
  	padding:0;
	}
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

#product .product-description,.product-description-short{
	h2{
		font-weight:300;
		@include font-size(1.875rem);
	}
	h1{
		@extend h2;
	}
	ul{
	  list-style-position: inside;	
	  margin-bottom:0.5rem;
	}
	li{
		list-style-type:square;
		&::marker {
		  font-size: 0.5rem;
		}
	}	
}

@include media-breakpoint-mobile {  
	.product-description-short{
		ul>li:nth-child(2)~li{
			display:none;	
		}
		a#link-more{display:none;}
		.toggle .btn{
			&:before {
			  content: attr(data-more);
			}
		}
		&.open{
			&+.toggle .btn:before {
				content: attr(data-less);
			}
		}
	}
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection{
  justify-content: space-between;
  align-items: center; 
}
.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-bottom: $spacer ;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}
.product-add-to-cart{
  .product-quantity{
  margin-top: -($spacer);
  }
.qty{
  margin: 0 $spacer;
  margin-top: $spacer;

}
.add{
  margin-top: $spacer;

	}
	.add-to-cart {
    &:disabled{display:none;}
  }
}
.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}
.sort-by-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
  .js-count .material-icons{
  	font-size:1.25rem;	
  }
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
//product tabs
.product-tabs{
	 margin-left:-15px;
    margin-right:-15px;
	.navTabs{
		background:$white;
		z-index:550;	
		position:relative;
		
		
	}
  .nav-tabs{
  	font-weight:700;
    font-size: 1.125rem;
    border-bottom:0px solid $white;
   
    .nav-link{
  		color:$primary;
  	  margin-bottom: 0px;
  	 	border: 0;
    	border-radius: 0;
    	border-bottom:3px solid transparent;
		}
		.nav-link.active{
	    border-bottom:3px solid $primary;
	  }
  }
  .tab-content{
  	padding-top:0;
	  	>section{
	 			@include media-breakpoint-mobile(){ 		
					border-bottom:1px solid $body-color;
					padding: 13px 0px;
					&:first-child{
						border-top: 1px solid $body-color;
					}
		 		}
				>p{
					font-weight:700;
					font-size:1.125rem;
					margin-bottom:0;
					line-height: 1.2;
					display: flex;
					align-items:center;
					justify-content:space-between;
					padding-left: 1rem;       
					padding-right: 0.5rem;
					&[data-toggle="collapse"]{
						&:after{
			  			@extend .material-icons;
			  			content:	"keyboard_arrow_right";
			  			transition: transform .3s ease-in-out;
			  			font-size:1.875rem;
			  		}
			  		&[aria-expanded=true]:after {
			    			transform: rotate(90deg);
					  }	
					   &+div{
				  		padding-top:1rem;	
				  	}		
					}	
				}
			}	

  }
  
}
.nav-tabs{
  justify-content: center;
}


.product-price-and-shipping{
	.price{
		font-size:1.125rem;	
		font-weight:$product-price-current-font-weight;	
	}	
	.regular-price{
		font-weight:$product-price-current-font-weight;	
		font-size:1.125rem;	
	}
}


.product_insuroption{
	span+span{
		padding-left:8px;
		font-weight: 700;
		text-decoration: underline;
	  text-underline-position: under;	
	}
}

.product-information{
	.dwf-page-link,.links{
		a{
			span.material-icons{
				margin-right: 12px;
			}
			
			font-weight: 700;
			text-decoration: underline;
		  text-underline-position: under;	
		}	
	}
}

.product-accessories_text{
	ul{
		@extend .row;
		justify-content: space-around;
		li{
			@include font-size(1.25rem);
			 @include media-breakpoint-down(md) {
  			@include font-size(1rem);
  		}
			font-weight:500;
			margin-bottom:$grid-gutter-width/2;
			@extend .col-6;
			@extend .col-md-4;
			@extend .col-lg-2;	
			img{
				width: 100%;
		    @extend .img-fluid;
		    display: block;
		    margin-bottom:0.8rem;
		    
			}	
		}
	}	

}

#guarantee_bar{
		background:$light;
    padding-bottom: 2rem;
    padding-top: 3rem;
}

.product-label_guarantee{
	display :flex;
  flex-wrap:wrap;
  justify-content:space-between;	
  /*align-items:center;	*/
  ul{
  	text-align:center;	
    color:$body-color;
    @extend .col-6;
		@extend .col-sm-3;
		@include media-breakpoint-up(sm) {
			padding-left:25px;
			padding-right:25px;
		}	
  	img{
  		@extend .img-fluid;
  		margin-bottom:0.8rem;	
  	}
  	strong{
  		@include font-size(1.125rem);
			 @include media-breakpoint-down(md) {
  			@include font-size(1rem);
  		}
  	}
  	@include media-breakpoint-down(md) {
  		li:nth-child(3){display:none;}	
  	}
  }
}

.product-group-features{
	text-align: center;

	.product-group-feature{
		background:$light;
		padding-top:15px;

		position:relative;
		@include media-breakpoint-up(lg){
			top:-3rem;
			&.old-product-group-feature{top:inherit;}
		}
		@extend .d-flex;		
		@extend .flex-wrap;
  	@extend .align-items-center;	
  	text-align: left;
  	>p{
  			@extend .col-12;
				@extend .col-sm-6;
				@extend .pr-sm-0;
  	}
  	ul{
  		@extend .col-12;
  		@extend .col-sm-6;	
			@include media-breakpoint-up(sm){
					
					padding-left: 3rem;
					padding-right: 3rem;
  		}	
  		@include media-breakpoint-up(xl){
					/*padding-left: 5rem;*/
  		}	
  	}
  	img{
			@extend .img-fluid;
			padding-bottom: 0.5rem;
		}
	}
  .product-extended-features &{
  		.product-group-feature{
  		}
  }
  @include media-breakpoint-mobile-portrait(){
  	margin-bottom:1.2rem;
		div[class*="col-12"]{
			padding-left:0px;
			padding-right:0px;
		}
	}
}

#product-video{
	margin-bottom:0;
	@include media-breakpoint-down(sm) {
		.slick-arrow{
			max-height: 50px;
			top: calc(50% - 25px);
			z-index:100;
    	padding-left: 0.5rem;
    	padding-right: 0.5rem;
		}
	}
	@include media-breakpoint-up(lg){
		.slick-arrow.slick-prev {
    	transform: translateX(-70px);
		}
		.slick-arrow.slick-next {
		    transform: translateX(75px);
		}
	}
	
}

#attachments{
	background:$light;

  padding-bottom: 2rem;
  padding-top: 3rem;
  .featuretext{@extend .table-responsive;}
		table{
			@extend .table;	
			@extend .table-striped;
			background-color:$light;
			tbody tr:nth-of-type(odd), .table-striped tbody tr:nth-of-type(odd){
				background-color:#d3d2c9;
			}
		}
}





