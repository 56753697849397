.l-header{
  background-color: $header-bg;
  margin-bottom: $spacer;
  transition: all 0.5s linear;
  color:$white;
  border-bottom:1px solid $header-bg;
   #index &,#product &,#category &{margin-bottom:0;}
   &.header--fixed{
		position: fixed;
		z-index: 1045;	
		width: 100%;
	}
  &.header--bg-transparent.headroom--top{
	  background-color: transparent;
	  border-bottom:1px solid #F0F0E6;
		 /*@include media-breakpoint-mobile() {
				box-shadow:none;
				color:$body-color;
		}*/
		/*.btn{
			color:$white;	
			@include media-breakpoint-mobile() {
				color:$body-color;
			}
		}
		.u-bor-bot{
			border-bottom:0;	
		}*/
	}
}
.headroom {
 /*transition: all 0.3s ease-out;*/
}
.headroom--pinned.headroom--not-top {
 	 position: relative !important;
}

.headroom--unpinned,
.headroom--pinned.headroom--not-top {
   position: fixed !important;
   width: 100%;
   top:0;
   background:$header-bg;
   max-width:100%;
   z-index:1000;
	#product &{
		position: relative !important;	
	}
}

.header__up{
  align-content: center;
}


.header-top{
	transition: .5s;
  flex-wrap: wrap;
  position:relative;
  padding-top:0;// $spacer*1.25;
  padding-bottom:0;// $spacer*1.25;
   /*@include media-breakpoint-desktop{
	  .headroom--not-top &{
	  	padding-top:0.8rem;
	  	padding-bottom:0.8rem;
	  }
	}*/
  @include media-breakpoint-mobile{
  	padding-top:0.5rem;
  	padding-bottom:0.5rem;
  }
}
.header__logo{
	@extend .ml-xl-4;
	@extend .ml-lg-3;
	&:not(use) >svg.logo{
		fill:$white;
		max-height:35px;
		max-width: 222px;
		transition: .5s;
		.headroom--not-top &{
				max-height:25px;
				max-width: 159px;
				margin-left:0;
		}
		@include media-breakpoint-mobile{
			max-height:25px;
			max-width: 159px;
			/*max-width:inherit;*/
		}
	}
	&:hover{
			&:not(use) >svg.logo{
				fill:$white;	
			}
	}
}
.header__right{
	.icon_svg:not(use) >svg{
		height:26px;
		width:26px;	
		fill:$white;
		@include media-breakpoint-mobile{
			max-height:21px;
			max-width: 21px;
		}
	}
	.user-info{
		.icon_svg:not(use) >svg{
			width:22px;
		}	
	}
	.dropdown-menu.show{
		left:auto;
		right:0;	
	}
}

.header__left{
	display: flex;
  align-items: center;
  justify-content: flex-start;
	.icon_svg:not(use) >svg{
		height:21px;
		width:21px;	
		fill:$white;
	}
	#menu-icon{
		color:$white;
		margin-right:0.5rem;
	}
}

.blockcart{
	position:relative;
  .cart-products-count{
  		position: absolute;
	    top: -3px;
	    right: -6px;
	    background:$secondary;
	    color: $white;
	    border-radius: 100%;
	    font-size:0.625rem;
	    font-weight:400;
	    padding:0.0625rem 0.1875rem;
	    line-height: 1;
  }
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  text-align: center;
  position: relative;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}

#_desktop_language_selector{
	.dropdown-toggle{
		text-transform: uppercase;
		color:$white;
		}	
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 55%;
  }
  #menu-icon{
  	padding:0;
  	.material-icons{
  			font-size:2.1875rem;
  	}
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 23%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 19%;
  }
}
@include media-breakpoint-up(xxl) {
  .header-top__col{
    width: 12%;
  }
}

.header_menu{flex-grow:1;}


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}
