//
// Color system
//
$body-color: #252525;
$white:         #fff;
$primary:       #0A1E32;
$secondary:#2887A3;
$black:         #000;
$gray-100: #f8f9fa;
$gray-200:      #e9ecef;
$gray-300 : #D7D7D7;
$gray-400: #ced4da;
$gray-600 : #868686;
$info: $body-color;
$light:   #F0F0E6;
$dark:$body-color;
$green:#557369;
$success:#557369;

// Options
$enable-rounded:            true;
$enable-shadows:            false;
$enable-responsive-font-sizes:   true;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl:1920px
);

//
// Links
//
// Style anchor elements.


$link-hover-decoration:     none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      'Hanken Grotesk',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        'Hanken Grotesk',$font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-weight-base:            300;
$headings-font-weight:        700;


$h1-font-size:                $font-size-base * 3.125;
$h2-font-size:                $font-size-base * 2.25;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// margin

$paragraph-margin-bottom:   0.5rem;
$headings-margin-bottom:1rem;

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

//input
$input-border-color:                    $gray-300;
$input-color:                           $body-color;
$input-box-shadow: none;
$input-border-radius :0;
$custom-select-border-radius : 0;
$custom-select-focus-width:0;

//buttons
$btn-font-weight:            500;
$btn-box-shadow:none;
$btn-active-box-shadow:none;
$input-btn-focus-box-shadow:none;
$btn-border-radius:0.5rem;
$btn-font-size-lg:1.125rem;
$btn-focus-box-shadow:none;
$btn-active-box-shadow:      none;

//breadcrumb

$breadcrumb-font-size:              0.75rem;
$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $body-color;
$breadcrumb-active-color:           $body-color;
$breadcrumb-divider:                quote("|");

//card
$card-border-width:                 0;
$card-cap-bg:transparent;
$card-border-radius:                0;
$card-border-color:                 #d7d7d7;


//tables
$table-bg : $white;
$table-accent-bg:$gray-100;
$table-border-color:$gray-300;
$table-dark-bg : $primary;
$table-dark-border-color :$primary;


//modal
$modal-content-border-radius : 0;

