//color

$bg-dark :                      #252525;
$tertiary:                      #252525;
$light-gray:                    #f7f7f7;

//option
$enable-fluid-layout:            true;


/* HEADER */
$header-bg:                     $primary;
$header-box-shadow:             none;
$header-nav-bg-color:           null;
$header-nav-color:              $body-color;
$header-nav-padding-y:          null;
$header-nav-border-color:       $border-color;
$header-top-border-color:       $header-nav-border-color;
/* MAIN */
$spacer-y : $spacer*2;
$wrapper-bg:                    #fff;
/* FOOTER */
$footer-bg:                     #F0F0E6;
$footer-container-margin-top:    $grid-gutter-width;
