.block-categories {
  @extend .card;
}

.block-categories{
  @extend .card-body;
}

.block-categories {
  box-shadow:$card-box-shadow;;
}



//block categories
.block-categories a{
  color:$body-color;
}
.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  border-bottom: 1px solid $border-color;
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}

@include media-breakpoint-down(sm) {
	#category-description{
		>div{
			padding-left:0;
			padding-right:0;	
		}	
	}
}
@include  media-breakpoint-mobile-portrait {
	.products .ebcategoryimage{
		padding-right: $grid-gutter-width * .25;
    padding-left: $grid-gutter-width * .25;
   }
}
