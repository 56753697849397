.slick-arrow {
    position: absolute;
    top:0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;

    & > i{
        font-size: 2*$font-size-base;
        line-height: 1;
        border-radius: 50%;
        color:$body-color;
    }

 }
.slick-prev {
    left:0;
}
.slick-next{
    right:0;
}
.slick-slide figure{
    margin: 0;
}
.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
        @include media-breakpoint-down(lg) {	
        	transform:translateX(0px);
        }
    }
    .slick-next{
        transform:translateX(50px);
        @include media-breakpoint-down(lg) {	
        	transform:translateX(0px);
        }
    }
}
.slick__arrow-large{
    .slick-arrow i{
        font-size: 3*$font-size-base;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        background: transparent;
        color: #fff;
    }
}

.slick-dots{
	    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
		li{
			position: relative;
	    display: inline-block;
	    margin: 0 4px;
	    padding: 0;
	    cursor: pointer;
	    button{
				background:$body-color;	
		  	opacity: 0.4;
		  	font-size: 0;
		    line-height: 0;
		    width: 0.5rem;
		    height: 0.5rem;
		    padding:0;
		    color: transparent;
		    border: 0;
		    outline: none;
		    border-radius: 100%;
	  	}
	  	&.slick-active button{
	  		opacity: 1;
	  	}	
		}
} 