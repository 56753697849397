.social-sharing{
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .align-items-center;
  @extend .px-0;
  @include media-breakpoint-mobile-portrait(){
  	justify-content:center;
	}
}
.social-sharing--product{
  margin: $spacer 0;
  @extend .align-items-center;
}
.modal .social-sharing--product{
  margin: 0;
}
.social-share-btn{
  display: block;
  margin: 0 $spacer/2;
  &:not(use) >svg{
  	 width: $social-share-size;
  	 height: $social-share-size;
  	 fill:$dark;
  }
  &.youtube:not(use) >svg{
  	 width: 2.3rem;
  	 height: 2.3rem;
  }
  &:hover{
  	svg{fill:$primary;}	
  }
  &.facebook:hover svg{fill:#1877F2;}
  &.twitter:hover svg{fill:#1DA1F2;}
  &.instagram:hover svg{fill:#C42D91;}
  &.youtube:hover svg{fill:#f00;}
  &.pinterest:hover svg{fill:#E60023;}
  &.linkedin:hover svg{fill:#0274b3;}
  &.tiktok:hover svg{fill:#000;}
  
  &:first-child{
  	margin-left:0;
  }
}
.social-share-btn--product{
  background-size:80%;
  background-color: #fff;
  @extend .shadow-sm;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out;
  &:hover{
    box-shadow:$card-box-shadow;;

  }

  &.facebook {
    background-image: url(../img/facebook-gray.svg);
    &:hover{
    background-image: url(../img/facebook-blue.svg);
    }
  }
  &.twitter {
    background-image: url(../img/twitter-gray.svg);
    &:hover{
      background-image: url(../img/twitter-blue.svg);
    }
  }
  &.googleplus {
    background-image: url(../img/gplus-gray.svg);
    &:hover{
      background-image: url(../img/gplus-blue.svg);
    }
  }
  &.pinterest {
    background-image: url(../img/pinterest-gray.svg);
    &:hover{
      background-image: url(../img/pinterest-blue.svg);
    }
  }

}
.social-share-btn--footer {
  transition: background-color 0.3s ease-in-out;
}
//
//.facebook {
//    background-image: url(../img/facebook.svg);
//  &::before{
//    content:"";
//    background-image: url(../img/facebook-blue.svg);
//  }
//  &.icon-gray {
//    background-image: url(../img/facebook-gray.svg);
//    &:hover {
//      background-image: url(../img/facebook-blue.svg);
//    }
//  }
//}
//.twitter {
//  background-image: url(../img/twitter.svg);
//  &::before{
//    content:"";
//    background-image: url(../img/twitter-blue.svg);
//  }
//  &.icon-gray {
//    background-image: url(../img/twitter-gray.svg);
//    &:hover {
//      background-image: url(../img/twitter-blue.svg);
//    }
//  }
//}
//.rss {
//  background-image: url(../img/rss.svg);
//}
//.youtube {
//  background-image: url(../img/youtube.svg);
//}
//.googleplus {
//  background-image: url(../img/gplus.svg);
//  //&::before{
//  //  content:"";
//  //  background-image: url(../img/gplus-blue.svg);
//  //}
//  &.icon-gray {
//    background-image: url(../img/gplus-gray.svg);
//    &:hover {
//      background-image: url(../img/gplus-blue.svg);
//    }
//  }
//}
//
//.pinterest {
//  background-image: url(../img/pinterest.svg);
//  &::before{
//    content:"";
//    background-image: url(../img/pinterest-blue.svg);
//  }
//  &.icon-gray {
//    background-image: url(../img/pinterest-gray.svg);
//    &:hover {
//      background-image: url(../img/pinterest-blue.svg);
//    }
//  }
//}
//.vimeo {
//  background-image: url(../img/vimeo.svg);
//}
//.instagram {
//  background-image: url(../img/instagram.svg);
//}
